import React from "react"

import { DocumentDisplay } from "../../components/common"
import cookiesPolicyDocument from "./cookiesPolicyDocument"

const CookiesPolicy = () => {
  return (
    <div className="mb-4">
      <DocumentDisplay doc={cookiesPolicyDocument} />
    </div>
  )
}

export default CookiesPolicy
