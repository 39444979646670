import React from "react"

import SEO from "../../components/Seo"
import PageWrapper from "../../components/PageWrapper"

import CookiesPolicy from "../../features/LegalStuff/CookiesPolicy"

const CookiesPolicyPage = ({ location }) => {
  return (
    <PageWrapper location={location}>
      <SEO title="Cookies Policy" />
      <CookiesPolicy />
    </PageWrapper>
  )
}

export default CookiesPolicyPage
